var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"width_center"},[_c('div',{staticClass:"go_0",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"hy-icon-fanhui",staticStyle:{"color":"#4e6ef2","font-size":"18px"}}),_vm._v(" 返回 ")]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.inner.messageContent))]),_c('div',{staticClass:"data"},[_c('i',{staticClass:"hy-icon-shijian1"}),_vm._v(" "+_vm._s(_vm.inner.createTime))]),(_vm.inner.articleContent)?_c('div',{staticClass:"img"},[_c('div',{staticClass:"articlContet",domProps:{"innerHTML":_vm._s(_vm.inner.articleContent)}}),_c('div',{staticClass:"content_all"},_vm._l((_vm.inner.fileList),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"fileListImg"},[(
                      item.storageName.slice(-4) == '.pdf' ||
                      item.storageName.slice(-4) == '.PDF'
                    )?_c('img',{attrs:{"src":require("../../assets/img/pdf.png"),"alt":""}}):_vm._e(),(
                      item.storageName.slice(-4) == '.doc' ||
                      item.storageName.slice(-5) == '.docx'
                    )?_c('img',{attrs:{"src":require("../../assets/img/word.png"),"alt":""}}):_vm._e(),_c('a',{attrs:{"href":_vm.baseUrl + 'third/third/ossGetFile/' + item.storageName,"target":"_blank"}},[_vm._v(_vm._s(item.originalName))])])])}),0)]):_c('div',{staticClass:"img"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.baseUrl+'third/third/ossGetFile/'+_vm.inner.messageImg}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }